<template>
<div>
     <!-- Hero Start -->
    <section class="bg-half d-table w-100" style="background: url('/images/company/aboutus.jpg');">
      <div class="bg-overlay "></div>
      <div class="container ">
            <div class="row justify-content-center ">
                <div class="col-lg-12 text-center">
                    <div class="page-next-level title-heading">
                        <div class="page-next">
                            <nav aria-label="breadcrumb" class="d-inline-block">
                                <ul class="breadcrumb bg-custom rounded shadow mb-0">
<!--                                    <li class="breadcrumb-item"><a href="Javascript:void(0)">{{ menu }}</a></li>-->
                                    <li class="breadcrumb-item active" aria-current="page">{{ sub_menu.name }}</li>
                                </ul>
                            </nav>
                        </div>
                    </div>
                </div><!--end col-->
            </div><!--end row-->
        </div> <!--end container-->
    </section><!--end section-->
    <div class="position-relative">
        <div class="shape overflow-hidden text-white">
            <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
            </svg>
        </div>
    </div>
    <!-- Hero End -->
    <section class="section">
        <div class="container">
            <div class="row align-items-center bg-custom" id="counter">
                <!--end col-->
                <div class="col-md-12 mt-4 pt-2 pb-4 mt-sm-0 pt-sm-0 ">
                    <div class="ms-lg-4">
                        <div class="section-title">
                          <b-img v-if="simple_data.images" class="mt-3 mr-3" fluid left :src="imgShow(simple_data.images)" alt="Left image"></b-img>
                          <h4 class="title text-custom mb-4 text-center">{{ simple_data.title }}</h4>
                          <div class="text-custom2" v-html="simple_data.description"></div>
                        </div>
                    </div>
                </div><!--end col-->
            </div><!--end row-->
        </div><!--end container-->
    </section><!--end section-->
</div>

</template>

<script>
import Vue from "vue";
import { BImg } from 'bootstrap-vue'
Vue.component('b-img', BImg)
export default {
  name: 'LeftSideImage',
  props:{
    menu:{
      required:true,
    },
    sub_menu:{
      required:true,
      type:Object,
    }
  },
  data(){
    return{
      simple_data:{},
    }
  },
  created(){
    this.simple_data = this.sub_menu.public_data_simple??{};
  },
  methods:{
    imgShow(path){
      return process.env.VUE_APP_ENV_RESOURCHURL+path;
    },
  },
  watch:{
    sub_menu(){
      this.simple_data = this.sub_menu.public_data_simple??{};
    }
  }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@import './../../assets/css/style-dark.css';
</style>
