<template>
  <div>
    <!-- Hero Start -->
    <section class="bg-half d-table w-100" style="background: url('/images/company/aboutus.jpg');">
      <div class="bg-overlay"></div>
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-12 text-center">
            <div class="page-next-level title-heading">
              <div class="page-next">
                <nav aria-label="breadcrumb" class="d-inline-block">
                  <ul class="breadcrumb bg-custom rounded shadow mb-0">
<!--                    <li class="breadcrumb-item"><a href="javascript:void(0)">{{ menu }}</a></li>-->
                    <li class="breadcrumb-item active" aria-current="page">{{ sub_menu.name }}</li>
                  </ul>
                </nav>
              </div>
            </div>
          </div><!--end col-->
        </div><!--end row-->
      </div> <!--end container-->
    </section><!--end section-->
    <div class="position-relative">
      <div class="shape overflow-hidden text-white">
        <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
        </svg>
      </div>
    </div>
    <!-- Hero End -->
    <section style="margin-top: -1%" class="section">
      <div class="">
        <div class="row justify-content-center">
          <div class="col-lg-9 mt-4 pt-2">
            <div class="col-12 text-center" data-v-baed62a8="">
              <div class="section-title mb-4 pb-2" data-v-baed62a8="">
                <h4 class="title" data-v-baed62a8="">Notice</h4>
              </div>
            </div>
            <div class="accordion bg-custom" id="accordionExample">
              <template v-for="(notice,key) in notices" >
                <div v-if="notice.is_file" class="accordion-item rounded mt-2" :key="key">
                  <div class="d-flex features feature-clean core-feature rounded shadow p-4">
                    <div class="icons text-center mx-auto">
                      {{ moment(notice.updated_at).format('Y-MM-DD') }}
                    </div>
                    <div class="flex-1 ms-4 text-center">
                      <a :href="imgShow(notice.data)" target="_blank"
                         class="title text-custom2 h5">{{ notice.title }}</a>
                    </div>
                  </div>
                </div>
                <div v-else class="accordion-item rounded" :key="key">
                  <div class="d-flex features feature-clean core-feature rounded shadow p-4">
                    <div class="icons text-center mx-auto">
                      {{ moment(notice.updated_at).format('Y-MM-DD') }}
                    </div>
                    <div class="flex-1 ms-4 text-center">
                      <span v-b-toggle="'collapse-'+key" class="title text-custom2 h5" style="text-align: center">{{ notice.title }}</span>
                      <b-collapse :id="'collapse-'+key" class="mt-2">
                        <b-card>
                          <p class="card-text">{{ notice.title }}</p>
                          <div v-html="notice.description"></div>
                        </b-card>
                      </b-collapse>
                    </div>
                  </div>
                </div>
              </template>
            </div>
          </div><!--end col-->
        </div><!--end row-->
      </div><!--end container-->
    </section>
  </div>

</template>

<script>
import moment from "moment"
export default {
  name: 'notice',
  props:{
    menu:{
      required:true,
    },
    sub_menu:{
      required:true,
      type:Object,
    }
  },
  data() {
    return {
      moment
    }
  },
  methods:{
    imgShow(path){
      return process.env.VUE_APP_ENV_RESOURCHURL+path;
    },
    /*toggle() {
      this.classList.contains("show") ? this.hide() : this.show()
    }*/
  },
  computed:{
    notices(){
      return this.sub_menu.public_data_multiple??[];
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@import './../../../assets/css/style-dark.css';
</style>
