<template>
  <div style="min-height: 500px" v-if="!loader">
    <no-image v-if="sub_menu && parseInt(sub_menu.page_design_type_id)===1" :sub_menu="sub_menu" :menu="menu"></no-image>
    <left-side-image v-else-if="sub_menu && parseInt(sub_menu.page_design_type_id)===2" :sub_menu="sub_menu" :menu="menu"></left-side-image>
<!--    <right-side-image></right-side-image>
    <multiple-image></multiple-image>-->
    <gallery-year v-else-if="sub_menu && parseInt(sub_menu.page_design_type_id)===3" :sub_menu="sub_menu" :menu="menu"></gallery-year>

        <gallery-video v-else-if="sub_menu && parseInt(sub_menu.page_design_type_id)===4" :sub_menu="sub_menu" :menu="menu"></gallery-video>

    <notice v-else-if="sub_menu && parseInt(sub_menu.page_design_type_id)===5" :sub_menu="sub_menu" :menu="menu"></notice>
    <events v-else-if="sub_menu && parseInt(sub_menu.page_design_type_id)===6" :sub_menu="sub_menu" :menu="menu"></events>
  </div>
</template>

<script>
/* eslint-disable */
import LeftSideImage from "./pages/LeftSideImage";
import NoImage from "./pages/NoImage";
/*import MultipleImage from "./pages/MultipleImage";
import RightSideImage from "./pages/RightSideImage";*/
import GalleryVideo from "./pages/gallery/GalleryVideo";
import GalleryYear from "./pages/gallery/GalleryYear";
import Notice from "./pages/notice/Notice";
import Events from "./pages/notice/Events";
export default {
  name: 'CommonPage',
  components:{
    LeftSideImage,NoImage,//MultipleImage,RightSideImage,
    GalleryVideo,
    GalleryYear,
    Notice,
    Events,
  },
  props:{
    menu:{
      required:true,
    },
    slug:{
      required:true,
    }
  },
  created() {
    this.$store.dispatch('GET_ALL_SUB_MENU_WITH_DATA');
  },
  computed:{
    sub_menu(){
      return this.$store.getters.single_sub_menus(this.slug);
    },
    loader() {
      return this.$store.getters.loader;
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@import './../assets/css/style-dark.css';
</style>