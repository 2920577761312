<template>
  <div>
    <!-- Hero Start -->
    <section class="bg-half d-table w-100" style="background: url('/images/company/aboutus.jpg');">
      <div class="bg-overlay"></div>
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-12 text-center">
            <div class="page-next-level title-heading">
              <div class="page-next">
                <nav aria-label="breadcrumb" class="d-inline-block">
                  <ul class="breadcrumb bg-white rounded shadow mb-0">
                    <li class="breadcrumb-item"><a href="javascript:void(0)">{{ menu }}</a></li>
                    <li class="breadcrumb-item active" aria-current="page">{{ sub_menu.name }}</li>
                  </ul>
                </nav>
              </div>
            </div>
          </div><!--end col-->
        </div><!--end row-->
      </div> <!--end container-->
    </section><!--end section-->
    <div class="position-relative">
      <div class="shape overflow-hidden text-white">
        <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
        </svg>
      </div>
    </div>
    <!-- Hero End -->
    <section class=" ">
      <div class="container">
        <div class="container p-5">
          <div class="row  mt-5">
            <div class="col-md-12 bg-custom" v-for="(event,key) in events" :key="key">
              <div class="card-group evet-card mb-2" >
                <div class="card mb-0" id="event_card">
                  <div class="card-body py-1">
                    <div class="row">
                      <div class="col-7 col-sm-12">
                        <div class="d-flex flex-column">
                          <div class="display-4 ">
                            <span class="badge">{{ moment(jsonDecode(event.data).event_date).format('d') }}</span>
                            <span class="month text_custom" style="margin-left: 5%">{{ moment(jsonDecode(event.data).event_date).format('MMMM') }}</span>
                          </div>
                          <h4 class="card-title text-uppercase mt-0 text-custom2">
                            <strong>{{ event.title }}</strong>
                          </h4>
                          <div class="card-text text-custom2" v-html="event.description.split(' ').slice(0, 40).join(' ')+'...'"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card mb-0" id="event_card">
                  <div class="card-body py-1">
                    <div class="row h-100">
                      <div class="col-5 col-sm-12 mt-2">
                        <ul class="list-group my-0 pl-1">
                          <li class="list-inline-item">
                            <i data-feather="clock" class="fea icon-sm icons"></i>
                            {{ moment(jsonDecode(event.data).start_time,'hh:mm:ss').format('hh:mm a')+' - '+moment(jsonDecode(event.data).end_time,'hh:mm:ss').format('hh:mm a') }}
                          </li>
                          <li class="list-inline-item">
                            <i data-feather="calendar" class="fea icon-sm icons"></i>{{ jsonDecode(event.data).event_date }}
                          </li>
                          <li class="list-inline-item">
                            <i data-feather="map-pin" class="fea icon-sm icons"></i>{{ jsonDecode(event.data).location }}
                          </li>
                        </ul>
                      </div>
                      <div class="col-7 col-sm-8 text_custom center">
                        <router-link :to="{name:'event.single',params:{id:event.id}}" exact class="btn btn-pills btn-primary text_custom">View
                          Details
                        </router-link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div><!--end container-->
    </section>

  </div>

</template>

<script>
import feather from 'feather-icons'
import moment from "moment";
export default {
  name: 'events',
  props:{
    menu:{
      required:true,
    },
    sub_menu:{
      required:true,
      type:Object,
    }
  },
  data(){
    return{
      moment,
    }
  },
  mounted() {
    feather.replace();
  },
  methods:{
    jsonDecode(data){
      let parse_data= {};
      try{
        parse_data =JSON.parse(data);
      }catch (e){parse_data={};}
      return parse_data;
    },
    imgShow(path){
      return process.env.VUE_APP_ENV_RESOURCHURL+path;
    },
  },
  computed:{
    events(){
      return this.sub_menu.public_data_multiple??[];
    },
  }
}
</script>
<style scoped>
@import './../../../assets/css/style-dark.css';
</style>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.center {
  text-align: center;
  margin: auto;
  width: 60%;
  padding: 10px;
}

.evet-card > .card .list-inline-item {
  color: #247444;
  font-size: 14px;
}

.evet-card > .card .badge {
  background: #247444;
  display: inline-block;
  padding: .25em .4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: .25rem;
}

.badgee {
  display: inline-block;
  padding: .25em .4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: .25rem;
}

.evet-card > .card {
  background: #FFFFF0;
}

@media (max-width: 1219px) {
  .evet-card > .card .badge {
    font-size: 3vw;
  }
}
</style>
