<template>
  <div>
    <!-- Hero Start -->
    <section class="bg-half d-table w-100" style="background: url('/images/company/aboutus.jpg');">
      <div class="bg-overlay"></div>
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-12 text-center">
            <div class="page-next-level title-heading">
              <div class="page-next">
                <nav aria-label="breadcrumb" class="d-inline-block">
                  <ul class="breadcrumb bg-custom rounded shadow mb-0">
<!--                    <li class="breadcrumb-item"><a href="javascript:void(0)">{{ menu }}</a></li>-->
                    <li class="breadcrumb-item active" aria-current="page">{{ sub_menu.name }}</li>
                  </ul>
                </nav>
              </div>
            </div>
          </div><!--end col-->
        </div><!--end row-->
      </div> <!--end container-->
    </section><!--end section-->
    <div class="position-relative">
      <div class="shape overflow-hidden text-white">
        <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
        </svg>
      </div>
    </div>
    <!-- Hero End -->
    <section class="section">
      <div class="container bg-custom">
        <div class="row">
          <div class="col-lg-3 col-md-6 col-12 mt-2 mb-2">
            <div class="sidebar sticky-bar text_custom p-4 rounded shadow">
              <!-- Posted -->
              <div class="widget" v-if="videos.length>0">
                <h5 class="widget-title">Categories</h5>
                <div class="mt-2" >
                  <div class="form-check mb-0">
                    <span @click="changeCategory(0)">
                      <input class="form-check-input" type="radio" v-model="category_id" :value="0" name="category">
                      <label class="form-check-label" >{{ 'All' }}</label>
                    </span>
                  </div>
                  <div class="form-check mb-0" v-for="(vv,key) in videos" :key="key">
                    <span @click="changeCategory(vv.id)">
                      <input class="form-check-input" type="radio" v-model="category_id" :value="vv.id" name="category">
                      <label class="form-check-label" >{{ vv.title }}</label>
                    </span>
                  </div>
                </div>
              </div>
              <!-- Posted -->
            </div>
          </div><!--end col-->
          <div class="col-lg-9 col-md-6 col-12 mt-2 mt-sm-0 pt-2 pt-sm-0">
            <div class="row mt-2 mb-2">
<!--              <div class="col-md-4">
                <LazyVimeo ref="vimeoLazyVideo"
                           :src="vimeoLink"
                           max-width="300px"
                           aspect-ratio="16:9"
                           thumbnail-quality="standard">
                </LazyVimeo>

                <div class="buttons">
                  <button @click="handleClick('playVideo', 'vimeoLazyVideo')"><i data-feather="play-circle"
                                                                                 class="fea icon-sm icons m-1"></i>Play
                  </button>
                  <button @click="handleClick('pauseVideo', 'vimeoLazyVideo')"><i data-feather="pause-circle"
                                                                                  class="fea icon-sm icons m-1"></i>Pause
                  </button>
                  <button @click="handleClick('resetView', 'vimeoLazyVideo')"><i data-feather="stop-circle"
                                                                                 class="fea icon-sm icons m-1"></i>Stop
                  </button>
                </div>
              </div>-->
              <div class="col-md-4" v-for="(vid,key) in show_videos" :key="key">
                <LazyYoutube
                    :src="vid"
                    max-width="300px"
                    aspect-ratio="16:9"
                    thumbnail-quality="standard"
                />
<!--                <div class="buttons">
                  <button @click="handleClick('playVideo', 'youtubeLazyVideo')"><i data-feather="play-circle"
                                                                                   class="fea icon-sm icons m-1"></i>Play
                  </button>
                  <button @click="handleClick('pauseVideo', 'youtubeLazyVideo')"><i data-feather="pause-circle"
                                                                                    class="fea icon-sm icons m-1"></i>Pause
                  </button>
                  <button @click="handleClick('resetView', 'youtubeLazyVideo')"><i data-feather="stop-circle"
                                                                                   class="fea icon-sm icons m-1"></i>Stop
                  </button>
                </div>-->
              </div>
              <h5 style="margin-left: 20%" v-if="videos.length <1">There is no video yet</h5>
            </div>
          </div><!--end col-->
        </div><!--end row-->
      </div><!--end container-->
    </section><!--end section-->
  </div>
</template>

<script>
import feather from 'feather-icons'
//import LazyYoutube from './LazyYoutube'
//import LazyVimeo from "./LazyVimeo";
import { LazyYoutube } from "vue-lazytube";
export default {
  name: 'gallery-video',
  props:{
    menu:{
      required:true,
    },
    sub_menu:{
      required:true,
      type:Object,
    }
  },
  data() {
    return {
      category_id:0,
      show_videos:[],
    }
  },
  components: {
    //LazyVimeo,
    LazyYoutube,
  },
  mounted() {
    // this.$refs['lazyVideo'].playVideo();
    feather.replace();
  },
  created() {
    let dd = this.videos.map(item=>JSON.parse(item.description));
    dd.map((item,key)=>{
      this.show_videos.push(...dd[key]);
    });
  },
  methods: {
    changeCategory(id){
      this.category_id=id;
    },
    handleClick(event, ref) {
      this.$refs[ref][event]()
    },
    handleSearch(e, platform) {
      if (platform === 'youtube')
        this.youtubeLink = e.target.value;
      else
        this.vimeoLink = e.target.value;
    }
  },
  computed:{
    videos(){
      return this.sub_menu.public_data_multiple??[];
    },
  },
  watch:{
    category_id(){
      this.show_videos=[];
      if (this.category_id == 0){
        let dd = this.videos.map(item=>JSON.parse(item.description));
        dd.map((item,key)=>{
          this.show_videos.push(...dd[key]);
        });
      }else{
        let index = this.videos.findIndex(item=>item.id==this.category_id);
        if (index>=0) {
          this.show_videos.push(...JSON.parse(this.videos[index].description));
        }
      }
    }
  }
}
</script>

<style scoped>
#app {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
}

h2 {
  margin-bottom: 0;
  margin-top: 2rem;
}

/* .buttons {
  margin-top: 25px;
} */
.buttons {
  text-align: center;
  margin: auto;
  padding: 10px;
}

button {
  background-color: #4CAF50;
  color: white;
  margin: 4px 2px;
  border: none;
}
</style>
