<template>
  <div>
    <!-- Hero Start -->
    <section class="bg-half d-table w-100" style="background: url('/images/company/aboutus.jpg');">
      <div class="bg-overlay"></div>
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-12 text-center">
            <div class="page-next-level title-heading">
              <div class="page-next">
                <nav aria-label="breadcrumb" class="d-inline-block">
                  <ul class="breadcrumb bg-custom rounded shadow mb-0">
<!--                    <li class="breadcrumb-item"><a href="javascript:void(0)">{{ menu }}</a></li>-->
                    <li class="breadcrumb-item active" aria-current="page">{{ sub_menu.name }}</li>
                  </ul>
                </nav>
              </div>
            </div>
          </div><!--end col-->
        </div><!--end row-->
      </div> <!--end container-->
    </section><!--end section-->
    <div class="position-relative">
      <div class="shape overflow-hidden text-white">
        <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
        </svg>
      </div>
    </div>
    <!-- Hero End -->
    <section class="section " >
      <div class="container bg-custom">
        <div class="row justify-content-center">
          <div class="col-lg-3 col-md-6 col-12 mt-2">
            <div class="sidebar sticky-bar p-4 rounded shadow">
              <!-- Posted -->
              <div class="widget text_custom" v-if="galaries.length>0">
                <h5 class="widget-title">Categories</h5>
                <div class="mt-4" >
                  <div class="form-check mb-0">
                    <span @click="changeCategory(0)">
                      <input class="form-check-input" checked type="radio" v-model="category_id" name="category" value="0">
                      <label class="form-check-label">All</label>
                    </span>
                  </div>
                  <div class="form-check mb-0" v-for="(gl,key) in galaries" :key="key">
                    <span @click="changeCategory(gl.id)">
                      <input class="form-check-input" v-model="category_id" :value="gl.id"  type="radio" name="category">
                      <label class="form-check-label">{{ gl.title+' '}} {{moment().format('Y') !== moment(gl.updated_at).format('Y')?moment(gl.updated_at).format('Y'):''}}</label>
                    </span>
                  </div>

                </div>
              </div>
              <!-- Posted -->
            </div>
          </div><!--end col-->
          <div class="col-lg-9 col-md-6 col-12 mt-2 pt-2 pt-sm-0">
            <div>
              <ul class="">
                <li
                    v-for="(image, index) in media"
                    :key="index"
                    style="display: inline-block; margin: 0 5px 5px 0"
                >
                  <img
                      :src="image.thumb"
                      style="height: 200px; width: 190px; cursor: pointer"
                      @click="openGallery(index)"
                  >
                </li>
              </ul>
              <LightBox
                  ref="lightbox"
                  :media="media"
                  :show-caption="true"
                  :show-light-box="false"
              />
              <h5 style="margin-left: 20%" v-if="galaries.length <1">There is no image yet</h5>
            </div>
          </div><!--end col-->
        </div><!--end row-->
      </div><!--end container-->
    </section><!--end section-->
  </div>
</template>


<script>
import LightBox from './LightBox'

import ('vue-it-bigger/dist/vue-it-bigger.min.css') // when using webpack
import moment from "moment";
export default {
  name: 'gallery-year',
  components: {
    LightBox,
  },
  props:{
    menu:{
      required:true,
    },
    sub_menu:{
      required:true,
      type:Object,
    }
  },
  data() {
    return {
      moment,
      images:[],
      category_id:0,
    }
  },
  created() {
    let dd = this.galaries.map(item=>JSON.parse(item.description));
    if (dd){
      dd.map((item,key)=>{
        this.images.push(...dd[key]);
      });
    }else this.images=[];
  },
  methods: {
    changeCategory(id){
      this.category_id=id;
    },
    openGallery(index) {
      this.$refs.lightbox.showImage(index)
    }
  },
  computed:{
    galaries(){
      let dd=this.sub_menu.public_data_multiple??[];
      dd = dd.sort(function (a,b){
        return moment(a.updated_at).format('YMMDD')<moment(b.updated_at).format('YMMDD')?1:-1;
      });
      return dd;
    },
    media(){
       const imageDomain = process.env.VUE_APP_ENV_RESOURCHURL;
      return this.images.map(item=>{
        return{
          thumb: imageDomain + item,
          src: imageDomain + item,
          caption: item,
        }
      })
    }
  },
  watch:{
    category_id(){
      this.images=[];
      if (this.category_id == 0){
        let dd = this.galaries.map(item=>JSON.parse(item.description));
        dd.map((item,key)=>{
          this.images.push(...dd[key]);
        });
      }else{
        let index = this.galaries.findIndex(item=>item.id==this.category_id);
        if (index>=0) {
          this.images.push(...JSON.parse(this.galaries[index].description));
        }
      }
    }
  }
}

</script>

<style>

</style>